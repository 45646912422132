import React from 'react'
import { Link } from 'react-scroll'
import logo from '../assets/MockUps/logoNew.png'
import logo2 from '../assets/MockUps/logotext.svg'
import { useLocation } from 'react-router-dom'
const BusinessManu = () => {
    const location = useLocation().pathname
    return (
        <>
            <div className='flex justify-center items-center flex-col pt-40'>
                <Link to="/">
                    <div className='flex items-center gap-2'>
                        <img src={logo} className='object-cover ' width={50} height={50} />
                        <img src={logo2} className='object-cover ' />
                    </div>
                </Link>
                {location === "/app/business-menu" ?
                    <div className='pt-6'>
                        <h2 className='text-[24px] font-semibold '>Your onboarding process has been completed by Stripe.</h2>
                        <p className='text-[18px] font-medium text-center'>You may safely close this page and return back to the app.</p>
                    </div>
                    :
                    location === "/app/subscriptions" ?
                        <div className='pt-6'>
                            <h2 className='text-[24px] font-semibold '>Your subscription was successful</h2>
                        </div>
                        :
                        location === "/app/boost" ?
                            <div className='pt-6'>
                                <h2 className='text-[24px] font-semibold '>Your boost was initiated successfully</h2>
                            </div>
                            :
                            location === "/app/staff" ?
                                <div className='pt-6'>
                                    <h2 className='text-[24px] font-semibold '>Your extra staff was added to the monthly bill successfully</h2>
                                </div>
                                :
                                location === "/app/branding" &&
                                    <div className='pt-6'>
                                        <h2 className='text-[24px] font-semibold '>Your branding subscription was successful</h2>
                                        <p className='text-gray-500 font-light text-center'>You may close this page and return to the app now.</p>
                                    </div>
                }

            </div>
        </>
    )
}

export default BusinessManu