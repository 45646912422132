import React, { useState } from 'react'
import { RiSendPlaneFill } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import DynamicPopup from '../GenericData/DynamicPopup';
import AfterSignUp from '../Home/AfterSignUp';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import MobileFooter from '../GenericData/MobileFooter';
import Instragram from '../../assets/MockUps/insta.svg';
import Facebook from '../../assets/MockUps/facebook.svg';
import Twitter from '../../assets/MockUps/twiter.svg';
// import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa6';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import JoinUs from '../Home/JoinUs';
// const schema = yup.object().shape({
//   email: yup.string().required("Email Address is required"),
// })
function Footer() {
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState("")
  const { t } = useTranslation()
  const schema = yup.object().shape({
    email: yup.string().required(t('email')),
  })
  const { register, reset, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const lng = useSelector((state) => state?.resize?.language)


  const OnSubmit = async (data) => {
    setShow(true)
    setEmail(data?.email)
    reset()
  }





  // useEffec(() => {
  //   const handleResize = () => {
  //     setWindowWidth(window.innerWidth);
  //   };

  //   // Add event listener for window resize
  //   window.addEventListener('resize', handleResize);

  //   // Remove event listener when component unmounts
  //   return () => window.removeEventListener('resize', handleResize);
  // }, []); // 


  return (
    <>
      {
        show && <DynamicPopup modalOpen={show} data={<AfterSignUp email={email} />} close={() => setShow(false)} />

      }
      <div className='w-full ' id="joinUs">
        {/* <div className='bg-joinUsbg bg-cover bg-center h-full py-[60px] xl:block lg:block md:block hidden'>
          <div className=' w-full  top-0 h-full '>
            <div className=' lg:pl-[120px] xl:pl-[120px] pl-[20px] xl:block lg:block hidden'>
              <h2 className='xl:text-[48px] lg:text-[48px] text-[24px] font-semibold max-w-[450px] pt-4 leading-10'>{t('joinWait')}</h2>
              <p className='text-[16px] font-normal text-[#616365] max-w-[500px] pt-[24px]'>{t('joinToShort')}</p>
              <form onSubmit={handleSubmit(OnSubmit)}>
                <div className='border border-[#A9ADB0] px-2 rounded-md flex items-center w-[330px] h-[50px] mt-[16px]'>
                  <input type="email" {...register('email')} className=' bg-transparent h-full focus:outline-none w-full px-2' name="email" placeholder={t('emailEnter')} />
                  <button type='submit' className='bg-[#004985] rounded-md gap-3 px-4 py-1.5 text-white flex items-center '>
                    <p>{t('send')}</p>
                    <RiSendPlaneFill />
                  </button>
                </div>
                {errors.email && (
                  <span className='text-red-500 text-sm '>{errors.email.message}</span>
                )}
              </form>
            </div>
          </div>
        </div> */}
        <JoinUs />
        {/* <div className='xl:hidden lg:hidden block'>
          <MobileFooter setShow={setShow} setEmail={setEmail} />
        </div> */}


        {/* Bottom Footer Links */}
        <div className='bg-white lg:px-[120px] xl:px-[120px] px-[20px] xl:py-[16px] lg:py-[16px] py-[10px] '>
          <div className='flex lg:flex-nowrap xl:flex-nowrap flex-wrap justify-between items-center gap-1'>
            <div className=''>
              <p className='text-[#616365] text-[16px] font-normal'>{t('copyRight')}</p>
            </div>
            <div className={`flex lg:justify-center lg:items-center xl:justify-center xl:items-center ${lng === "pl" && 'w-[40%]'} `}>
              <p className='text-[#616365] text-[16px] font-normal'>{t('query')} <span className='text-[#004985]'>{t('queryEmail')}</span></p>
            </div>
            <div className='flex lg:flex-row xl:flex-row  flex-wrap justify-center  xl:justify-between lg:justify-between items-center  gap-4 '>
              <div className={`${lng === "en" ? 'flex items-start xl:w-auto lg:w-auto w-full justify-between gap-2' : 'flex items-end  w-full justify-end gap-2'}`}>
                <Link to={lng === "en" ? '/legal/privacy-policy/en' : '/legal/privacy-policy/pl'} className='text-[#616365] text-[16px] font-normal'>{t('privacy')}</Link>
                <Link to={lng === "en" ? '/legal/cookie-policy/en' : '/legal/cookie-policy/pl'} className='text-[#616365] text-[16px] font-normal'>{t('cookie')}</Link>
                <Link to={lng === "en" ? '/legal/terms-and-conditions/en' : '/legal/terms-and-conditions/pl'} className='text-[#616365] text-[16px] font-normal'>{t('terms')}</Link>
              </div>
              <div className={lng === 'en' ? 'flex justify-between items-center gap-4' : 'flex justify-end w-full items-end gap-4'}>
                <a href='https://www.instagram.com/rezerve.pl?igsh=cjlsdDAwOXV6Nmlp&utm_source=qr' target='_blank'>
                  <img src={Instragram} alt="insta" />
                </a>
                <a href='https://www.facebook.com/profile.php?id=61557559317957' target='_blank'>
                  <img src={Facebook} alt="insta" />
                </a>
                <a href='https://twitter.com/rezervepl' target='_blank'>
                  <img src={Twitter} alt="insta" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
