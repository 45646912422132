import React from 'react'
import Banner from '../components/Home/Banner';
import Offer from '../components/Home/Offer'
import Choose from '../components/Home/Choose'
import Information from '../components/Home/Information'
import WhoWe from '../components/Home/WhoWe'
import Footer from '../components/Layout/Footer';
const Home = () => {
    return (
        <>
            <div className='overflow-hidden'>
                <Banner />
                <Offer id="service" />
                <Choose id="feature" />
                <Information id="forSellers" />
                <WhoWe />
                <Footer />
            </div>
        </>
    )
}

export default Home