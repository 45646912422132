import React, { useEffect } from 'react'; //
import { useLocation, useParams } from 'react-router-dom';

const Business = () => {
    let { id } = useParams();  // Destructure id from useParams

    let location = useLocation()


    let getMobileOperatingSystem = () => {
        // Parse query parameters from the search part of the URL
        const queryParams = new URLSearchParams(location.search);
        // Check for the 'redirect' query parameter
        // const redirect = queryParams.get("redirect");
        // if (redirect === "false") {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/windows phone/i.test(userAgent)) {
            alert("Unsupported OS");
        } else if (/android/i.test(userAgent)) {
             // Get the current full URL
            const currentUrl = window.location.href;

            // Construct the Safari link using the current URL
            const safariLink = `rezerve.pl://${currentUrl}`;

            // Redirect to the Safari link
            window.location.href = safariLink;
            // window.location.replace("https://apps.apple.com/app/rezerve/id6496685025");
            window.location.replace(`https://play.google.com/store/apps/details?id=com.techubllc.rezerve`);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Get the current full URL
            const currentUrl = window.location.href;

            // Construct the Safari link using the current URL
            const safariLink = `rezerve.pl://${currentUrl}`; //

            // Redirect to the Safari link
            window.location.href = safariLink;
            // window.location.replace("https://apps.apple.com/app/rezerve/id6496685025");
        } else {
            // Assuming open_web is some condition you check in your state or props
            const openWeb = true; // Replace this with the actual condition
            if (openWeb) {
                let url = `https://rezerve.pl/`;
                window.location.replace(url);
            }
        }
        // }
        // else {
        //     // Get the current pathname and search parameters
        //     const currentPath = window.location.pathname;
        //     const currentSearch = window.location.search;

        //     // Construct the new URL
        //     const newUrl = `https://rezerve.app.link${currentPath}${currentSearch}`;

        //     // Redirect to the new URL
        //     window.location.href = newUrl;
        // }
    }

    useEffect(() => {
        getMobileOperatingSystem();
    }, []);

    return (
        <>
            {/* Your component content goes here if any */}
        </>
    );
};

export default Business;
