"use client"
import React, { useEffect } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Scroll } from '../GenericData/Generic';
import { useTranslation } from "react-i18next";


function Sidebar({ sidebarOpen, setSidebarOpen }) {
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    useEffect(() => {
        if (sidebarOpen === true) {
            if (typeof window != "undefined" && window.document) {
                document.body.style.overflow = "hidden";
            }
        } else {
            document.body.style.overflow = "auto";
        }
    }, [sidebarOpen]);

    const { t } = useTranslation();
    return (
        <div>
            {/* Sidebar backdrop (mobile only) */}
            <div
                className={`fixed inset-0 bg-slate-900 bg-opacity-30  transition-opacity duration-200 ${sidebarOpen
                    ? "opacity-100 z-50"
                    : "opacity-0 pointer-events-none"
                    }`}
                aria-hidden="true"
                onClick={() => setSidebarOpen(false)}
            ></div>

            {/* Sidebar */}

            <div
                className={` flex flex-col  w-full  transition-opacity duration-300 transform ease-in-out rounded-tr-[20px] rounded-tl-[20px] fixed l z-[999] bottom-0 left-0  ${sidebarOpen
                    ? "opacity-100"
                    : "opacity-0 pointer-events-none"
                    } transform ease-in-out border bg-white`}
            >


                <div className="">
                    <div className="h-full">
                        <ul className="flex flex-col justify-center mx-[20px] p-0 my-[20px] gap-[10px]">
                            <li
                                className={`items-center gap-[24px] flex justify-center border-b py-3 cursor-pointer text-[16px] font-normal`}
                            >
                                <Scroll to="service" setSidebarOpen={setSidebarOpen}>{t('service')}</Scroll>
                            </li>
                            <li
                                className={`items-center gap-[24px] flex justify-center border-b py-3 cursor-pointer text-[16px] font-normal`}
                            >
                                <Scroll setSidebarOpen={setSidebarOpen} to="feature">{t('features')}</Scroll>
                            </li>
                            <li
                                className={`items-center gap-[24px] flex justify-center border-b py-3 cursor-pointer text-[16px] font-normal`}
                            >
                                <Scroll setSidebarOpen={setSidebarOpen} to="forSellers">{t('forSellon')}</Scroll>
                            </li>
                            {/* <li>
                                <Scroll setSidebarOpen={setSidebarOpen} to="joinUs">
                                    <div className='flex justify-between group hover:bg-[#176B87] delay-150 text-[#004985] hover:text-white cursor-pointer border-[#176B87] items-center gap-2  border-[2px] rounded-lg w-full  px-5 h-[48px]'>
                                        <span>{t('joinWait')}</span>
                                        <FaArrowRightLong className='transition-transform delay-150 transform group-hover:translate-x-2' />
                                    </div>
                                </Scroll>
                            </li> */}
                            <li className="flex justify-between gap-2 items-center">
                                <div className='w-full'>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.techubllc.rezerve"
                                        target="_blank"
                                        class="flex items-center cursor-pointer border justify-center w-full  px-2 text-center text-white bg-white rounded"
                                        rel="noreferrer">
                                        <svg class="w-5" viewBox="-9 0 274 274" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g>
                                                <path
                                                    d="M188.81319,178.874645 C221.272218,161.051727 245.880297,147.470853 248.001319,146.415618 C254.78648,142.806714 261.79324,133.256838 248.001319,125.838536 C243.548228,123.506467 219.573289,110.347687 188.81319,93.3795092 L146.171146,136.443648 L188.81319,178.874645 Z"
                                                    fill="#FFD900"></path>
                                                <path
                                                    d="M146.171146,136.443648 L10.3940643,273.286517 C13.5808739,273.708611 17.1792251,272.864423 21.4212696,270.532353 C30.3274526,265.657168 124.739324,214.098388 188.81319,178.885198 L146.171146,136.443648 Z"
                                                    fill="#F43249"></path>
                                                <path
                                                    d="M146.171146,136.443648 L188.81319,93.5905562 C188.81319,93.5905562 30.9711459,7.45172685 21.4212696,2.36549437 C17.8229184,0.233919759 13.7919209,-0.399221214 10.1830173,0.233919759 L146.171146,136.443648 Z"
                                                    fill="#00EE76"></path>
                                                <path
                                                    d="M146.171146,136.443648 L10.1830173,0.233919759 C4.6641385,1.51075405 0,6.38593954 0,16.3579099 C0,32.270853 0,244.003747 0,257.162527 C0,266.290309 3.60890354,272.864423 10.3940643,273.497564 L146.171146,136.443648 Z"
                                                    fill="#00D3FF"></path>
                                            </g>
                                        </svg>
                                        <div class="flex flex-col ml-1 py-1 text-left ">
                                        <span class="text-[10px] text-black">{t('andorid_download')}</span>
                                            <span class="text-base font-normal leading-3  text-black">
                                                {t('Google')}
                                            </span>
                                        </div>
                                </a>
                            </div>
                            <div className='w-full'>
                                {/* <Image src={GoogleApp} className='object-contain' width={120} height={43} /> */}
                                <a href="https://apps.apple.com/app/rezerve/id6496685025"
                                    target="_blank"
                                    className="flex items-center  border cursor-pointer justify-center w-full px-2  text-center text-white bg-white rounded  md:w-auto"
                                    rel="noreferrer">
                                    <svg className="w-5" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0)">
                                            <path
                                                d="M32.6226 23.7016C32.6026 20.0267 34.2591 17.253 37.6118 15.2103C35.7359 12.5167 32.902 11.0347 29.1601 10.7443C25.6177 10.464 21.7461 12.8171 20.3292 12.8171C18.8324 12.8171 15.3998 10.8445 12.7057 10.8445C7.13769 10.9346 1.22048 15.3004 1.22048 24.1822C1.22048 26.8057 1.69945 29.516 2.65738 32.3131C3.93461 35.988 8.54465 45 13.3542 44.8498C15.8688 44.7897 17.645 43.0574 20.9179 43.0574C24.091 43.0574 25.7375 44.8498 28.5414 44.8498C33.3909 44.7797 37.5619 36.5888 38.7793 32.9039C32.2733 29.8298 32.6226 23.8919 32.6226 23.7016ZM26.9748 7.25968C29.6989 4.01535 29.4494 1.06142 29.3696 0C26.9648 0.140187 24.1808 1.64219 22.5943 3.49466C20.848 5.4773 19.8203 7.93058 20.0398 10.6943C22.6442 10.8945 25.019 9.55274 26.9748 7.25968Z"
                                                fill="black"></path>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0">
                                                <rect width="40" height="45" fill="white"></rect>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div class="flex flex-col ml-2 py-1 text-left">
                                    <span class="text-[10px] text-black">{t('ios_download')}</span>
                                        <span class="text-base leading-4/= font-normal text-black">
                                            App Store
                                        </span>
                                    </div>
                                </a>
                            </div>
                            {/* <li className="flex justify-between gap-2 items-center">
                                <Image src={GoogleApp} className='object-contain border rounded-md  h-[45px]' width="100%" height={60} />
                                <Image src={AppleApp} className='object-contain border rounded-md  h-[45px]' width="100%" height={60} />
                            </li> */}
                        </li>

                    </ul>
                </div>
            </div>
        </div>


        </div >
    );
}

export default Sidebar;