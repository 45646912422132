import React, { useState } from 'react'
import { HiOutlineBars3 } from "react-icons/hi2";
import logo from '../../assets/MockUps/logoNew.png'
import logo2 from '../../assets/MockUps/logotext.svg'
import LanguageSelector from '../GenericData/LanguageSelectore';
import Sidebar from './Sidebar';
import { useTranslation } from 'react-i18next';
import { ScrollWeb } from '../GenericData/Generic';
import { Link } from 'react-router-dom'
import SocialIcons from '../GenericData/SocialIcons';
function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='xl:px-[120px] lg:px-[120px]  relative'>
        {/* Header Section */}
        <div className=' flex justify-between items-center'>
          <div className='flex  items-center gap-[24px] xl:h-[96px] lg:h-[96px] h-[76px] xl:shadow-none lg:shadow-none md:shadow-none shadow-md xl:bg-transparent lg:bg-transparent bg-white z-30 xl:pl-0 lg:pl-0 pl-[20px] lg:relative xl:relative fixed w-full'>
            <div className='xl:hidden lg:hidden  flex justify-between items-center gap-[52px] w-full'>
              <div className=''>
                <HiOutlineBars3 size={30} onClick={() => setSidebarOpen(true)} className='cursor-pointer' />
              </div>
              <div className='w-full'>
                <Link to="/">
                  <div className='flex items-center gap-2'>
                    <img src={logo} className='object-cover ' width={50} height={50} />
                    <img src={logo2} className='object-cover ' />
                  </div>
                </Link>
              </div>
              <div className='w-[48%]'>
                <LanguageSelector />
              </div>
            </div>
            <div className='xl:block lg:block md:block hidden'>
              <Link to="/">
                <div className='flex items-center gap-2'>
                  <img src={logo} className='object-cover ' width={50} height={50} />
                  <img src={logo2} className='object-cover ' />
                </div>
              </Link>
            </div>
            <ul className=' items-center gap-[24px] cursor-pointer text-[16px] font-normal xl:flex lg:flex md:flex hidden '>
              <li>
                <ScrollWeb to="service">{t('service')}</ScrollWeb>
              </li>
              <li>
                <ScrollWeb to="feature">{t('features')}</ScrollWeb>
              </li>
              <li>
                <ScrollWeb to="forSellers">{t('forSellon')}</ScrollWeb>
              </li>
              <li>
                <ScrollWeb to="joinUs">{t('joinUs')}</ScrollWeb>
              </li>
            </ul>

          </div>
          <div className='w-[50%] flex justify-end items-end'>
            <SocialIcons />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header