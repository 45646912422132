import React from 'react'
import ServiceInfo from '../../assets/ServiceInfo.svg'
import { ChooseDataSellon } from '../../partials/ContentData'
import { useTranslation } from 'react-i18next'
import Service1 from '../../assets/Rectangle 32.svg'
import Service2 from '../../assets/Rectangle 33.svg'
import Service3 from '../../assets/Rectangle 35.svg'
import Service4 from '../../assets/Rectangle 36.svg'
import Service5 from '../../assets/Rectangle 37.svg'
import Service6 from '../../assets/Rectangle 38.svg'
import Service7 from '../../assets/Rectangle 39.svg'
import Service8 from '../../assets/Rectangle 40.svg'
import Service9 from '../../assets/hair1.jpg'
import Service10 from '../../assets/hair2.jpg'
import Service11 from '../../assets/hair3.jpg'
import Service12 from '../../assets/hair4.jpg'
import Service13 from '../../assets/hair5.jpg'
import Service14 from '../../assets/hair6.jpg'
import Service15 from '../../assets/hair4.jpg'
import Service16 from '../../assets/hair2.jpg'
import Carousel from 'react-multi-carousel'
function Information({ id }) {
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div className='information lg:px-[120px] xl:px-[120px] px-[20px] xl:py-[10px] lg:py-[10px] xl:pb-[40px] lg:pb-[40px] py-[20px] ' id={id}>

      <div className='flex justify-center items-center relative'>
        <div className=''>
          <h2 className='service_text font-semibold xl:text-[64px] lg:text-[64px] text-[28px] '>{t('features')}</h2>
        </div>
        <h2 className='xl:text-[48px] lg:text-[48px] text-white absolute xl:top-8 lg:top-8 md:top-3 top-3 text-[24px] font-semibold xl:leading-[56px] lg:leading-[56px]'>{t('information')}</h2>
      </div>
      <div className='grid xl:grid-cols-2  lg:grid-cols-2 grid-cols-1 gap-10 pt-[48px]'>
        {/* <img src={Service} width={612} height={383} alt="service_pic" /> */}
          <div className='xl:mt-32 lg:mt-32'>
            <Carousel
              responsive={responsive}
              containerClass=" "
              showDots={false}
              autoPlay={true}
              autoPlaySpeed={2000}
              arrows={false}
              infinite
            >
               <div className='flex flex-col xl:pr-0 lg:pr-0 pr-2 xl:pl-2 lg:pl-2 gap-1.5 '>
                <img src={Service4} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service2} className='w-[120px] h-full rounded-2xl ' alt="service_pic" />
              </div>
              <div className='flex flex-col pt-10  xl:pl-2 lg:pl-2 gap-1.5 '>
                <img src={Service3} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service4} className='w-[120px] h-full rounded-2xl ' alt="service_pic" />
              </div>
              <div className='flex flex-col  xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service5} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service6} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
              </div>
              <div className='flex flex-col  pt-10 xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service7} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service8} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
              </div>
              <div className='flex flex-col  xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service9} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service10} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
              </div>
              <div className='flex flex-col pt-10 xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service11} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service12} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
              </div>
              <div className='flex flex-col   xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service13} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service14} className='w-[120px]  h-full rounded-2xl' alt="service_pic" />
              </div>
              <div className='flex flex-col pt-10 xl:pr-0 lg:pr-0 pr-2 xl:pl-2 lg:pl-2 pl-2 gap-1.5'>
                <img src={Service15} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
                <img src={Service16} className='w-[120px] h-full rounded-2xl' alt="service_pic" />
              </div>

            </Carousel>
          </div>
        <div>
          {ChooseDataSellon?.map((list, i) => (
            <div className=' cursor-pointer ' key={i}>
              <div className='flex items-start gap-2 mb-3'>
                <div className='lg:w-[12%] xl:w-[12%] w-[20%] md:w-[10%]  xl:block lg:block md:block '>
                  <img
                    src={ServiceInfo}
                    height={"100%"}
                    alt="service Info"
                    className='lg:w-[80px] lg:h-[80px] xl:w-[80px] xl:h-[80px] w-[80px] h-[80px]'
                  />
                </div>
                <div className='flex flex-col w-full '>
                  <h2 className='text-[20px] text-white  font-semibold'>{t(list?.heading)}</h2>
                  <p className='text-[14px] text-white font-normal pt-[8px] leading-[19px]'>{t(list?.subHeading)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div >
  )
}

export default Information
