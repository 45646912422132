import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
// import Banner from '../components/Terms/Banner'
// import TermsList from '../components/Terms/TermsList'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const TermsConditionPl = () => {
  // return (
  //   <Layout>
  //     <Banner />
  //     <TermsList />
  //   </Layout>
  // )
  const  language  = useSelector((state) => state?.resize?.language)
  const navigate = useNavigate()

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.iubenda.com/iubenda.js';
    script.async = true;
    document.body.appendChild(script);


    if (language === "en") {
      navigate('/legal/terms-and-conditions/en')
    } else {
      navigate('/legal/terms-and-conditions/pl')
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [language]);


  return (
    <Layout>

      <div
        className='xl:px-[100px] lg:px-[1200px ] terms'
        dangerouslySetInnerHTML={{
          __html: `<a href="https://www.iubenda.com/zasady-i-warunki/88332244" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Zasady i warunki">Zasady i warunki</a>`,
        }}
      />
    </Layout>
  )
}

export default TermsConditionPl