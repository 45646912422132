
import { OfferData } from '../../partials/ContentData';
import CustomSlider from '../../partials/CustomSlider'
import React from 'react'
import { useTranslation } from 'react-i18next';

function Offer({ id }) {
  const { t } = useTranslation();
  return (
    <div className='bg-[#F0F2F4] lg:px-[120px] xl:px-[120px] px-[20px] xl:pt-[20px] lg:pt-[20px] xl:pb-[60px] lg:pb-[60px] py-[20px] ' id={id} >

      <div className='flex justify-center items-center relative'>
        <h2 className='service_text font-semibold xl:text-[64px] lg:text-[64px] text-[28px] '>{t('service')}</h2>
        <h2 className='xl:text-[48px] lg:text-[48px]  absolute xl:top-9 lg:top-9 md:top-5 top-3 text-[24px] font-semibold xl:leading-[56px] lg:leading-[56px]'>{t('whatOffer')}</h2>
      </div>
      {/*Carusel  */}
      <div className='xl:pt-[40px] lg:pt-[40px] pt-[10px]'>
        <div className='grid xl:grid-cols-5 lg:grid-cols-5 gap-3 md:grid-cols-3'>
          {
            OfferData?.map((list, i) => (
              <div className='' key={i}>
                <div className="relative w-full cursor-pointer xl:h-[132px] lg:h-[132px] max-h-full h-[132px] rounded-lg overflow-hidden shadow-lg group">
                  <img
                    src={list?.img}
                    alt="Hairdressers Hover"
                    className="w-full h-full object-cover transition-transform duration-300 transform group-hover:scale-110 absolute top-0 left-0 opacity-100 ease-in-out "
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out group-hover:bg-gradient-to-t group-hover:from-[#004985] group-hover:via-transparent group-hover:to-transparent group-hover:opacity-90"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-4 text-white font-bold transition-opacity duration-300 ease-in-out">
                    {t(list?.heading)}
                  </div>
                </div>

              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Offer