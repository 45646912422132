import React from 'react'
import { LoginButtons } from '../GenericData/SocialButtons'
import phoneImg from '../../assets/MockUps/cus.png'
import phoneImg2 from '../../assets/MockUps/cus2.png'
import { useTranslation } from 'react-i18next';
const JoinUs = () => {
    const { t } = useTranslation()
    return (
        <>
            <div className='information w-full h-full xl:px-[120px] lg:px-[120px] px-[20px] xl:pb-0 lg:pb-0 pb-4'>
                <div className='grid xl:grid-cols-2 lg:grid-cols-2 '>
                    <div className='flex justify-center items-center pt-5 xl:hidden lg:hidden md: '>
                        <img src={phoneImg2} alt="customer_img" className='object-contain h-[310px]' />
                    </div>
                    <div className='pt-[48px]'>
                        <h2 className='text-white font-semibold text-[28px]'>{t('download_heading')}</h2>
                        <p className='text-white leading-[23px] text-[20px] pt-[12px] font-normal '>{t('download_text')}</p>
                        <div className='pt-[12px]'>
                            <LoginButtons />
                        </div>
                    </div>
                    <div className=' justify-end items-end pt-5 xl:flex lg:flex hidden  '>
                        <img src={phoneImg} alt="customer_img" className='object-contain h-[310px] ' />
                    </div>
                </div>
            </div>


        </>
    )
}

export default JoinUs