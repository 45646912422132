import Hero from '../../assets/MockUps/hero.svg'
// import { FaArrowRightLong } from "react-icons/fa6";
import { ScrollWeb } from '../GenericData/Generic'
import { HiOutlineBars3 } from "react-icons/hi2";
import Sidebar from '../Layout/Sidebar'
import LanguageSelector from '../GenericData/LanguageSelectore'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import logo from '../../assets/MockUps/logoNew.png'
import logo2 from '../../assets/MockUps/logotext.svg'
import phonePl from '../../assets/MockUps/PhoneP.png'
import phoneNew from '../../assets/MockUps/PhoneE.png'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import SocialIcons from '../GenericData/SocialIcons';
import { LoginButtons } from '../GenericData/SocialButtons';
function Banner() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const lng = useSelector((state) => state?.resize?.language)

  const { t } = useTranslation()

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className='flex justify-between md:pb-[60px] pb-[90px] relative w-full '>
        <div className='xl:pl-[120px] lg:pl-[120px]  relative'>
          {/* Header Section */}
          <div>
            <div className='flex  items-center gap-[24px] xl:h-[96px] lg:h-[96px] h-[76px] xl:shadow-none lg:shadow-none md:shadow-none shadow-md xl:bg-transparent lg:bg-transparent  bg-white z-30 xl:pl-0 lg:pl-0 pl-[20px] lg:relative xl:relative fixed w-full'>
              <div className='xl:hidden lg:hidden  flex justify-between items-center gap-[52px] w-full'>
                <div className=''>
                  <HiOutlineBars3 size={30} onClick={() => setSidebarOpen(true)} className='cursor-pointer' />
                </div>
                <div className='w-full '>
                  <Link to="/">
                    {/* <img src={logo} className='object-cover ' width={210} height={50} /> */}
                    <div className='flex items-center justify-center gap-2 '>
                      <img src={logo} className='object-cover ' width={50} height={50} />
                      <img src={logo2} className='object-cover ' />
                    </div>
                  </Link>
                </div>
                <div className='w-[80%] md:w-[20%] flex justify-end items-end'>
                  <LanguageSelector />
                </div>
              </div>
              <Link to="/" className='xl:block lg:block  hidden'>
                {/* <img src={logo} className='object-cover ' width={210} height={50} /> */}
                <div className='flex items-center gap-2'>
                  <img src={logo} className='object-cover ' width={50} height={50} />
                  <img src={logo2} className='object-cover ' />
                </div>
              </Link>
              <ul className=' items-center gap-[24px] cursor-pointer text-[16px] font-normal xl:flex lg:flex hidden '>
                <li>
                  <ScrollWeb to="service">{t('service')}</ScrollWeb>
                </li>
                <li>
                  <ScrollWeb to="feature">{t('features')}</ScrollWeb>
                </li>
                <li>
                  <ScrollWeb to="forSellers">{t('forSellon')}</ScrollWeb>
                </li>
                <li>
                  <ScrollWeb to="joinUs">{t('joinUs')}</ScrollWeb>
                </li>
              </ul>
            </div>
            {/* Hero Section */}
            <div className='xl:pt-[100px] lg:pt-[100px] pt-[150px]   xl:pl-0 lg:pl-0 pl-[20px] '>
              <div className=''>
                <h2 className='lg:text-[40px] xl:text-[40px] text-[24px] font-semibold xl:leading-10 lg:leading-10 leading-6'>{t('findService')}
                  <br />
                  <span className='text-[#004985]'>{t('bookAppoint')}</span> </h2>
                <p className='text-[#84878A] font-normal lg:text-[20px] xl:text-[20px]  text-[18px] leading-[23px] xl:max-w-[437px] lg:max-w-[437px] tablet:max-w-[300px] pt-[24px]'>{t('Rezerve')}</p>
                {/* <ScrollWeb to="joinUs">
                  <div className='flex group hover:bg-[#004985] delay-150 text-[#004985] hover:text-white cursor-pointer border-[#004985] items-center gap-2 mt-[24px] border-[2px] rounded-full w-max px-5 h-[48px]'>
                    <span>{t('joinWait')}</span>
                    <FaArrowRightLong className='transition-transform delay-150 transform group-hover:translate-x-2' />
                  </div>
                </ScrollWeb> */}
                <div className='mt-[24px] w-full'>
                  <LoginButtons />
                </div>
               
                <div className='pt-[16px] xl:hidden lg:hidden tablet:hidden block mobile_hero '>
                  <img src={lng === "en" ? phoneNew : phonePl} className='object-contain' width={393} height={370} />
                </div>

                <div className=' pt-[24px]'>
                  <h2 className='text-[20px] font-semibold '>{t('didYou')}</h2>
                  <div className='grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 gap-4 md:w-full  max-w-[618px] '>
                    <div className='max-w-[190px] xl:border-r lg:border-r '>
                      <h2 className='text-[#004985] opacity-[0.5] text-[56px] font-semibold '>50%</h2>
                      <p className='leading-[17px] text-[16px] font-normal'>{t('ofSalon')}</p>
                    </div>
                    <div className='max-w-[190px] xl:border-r lg:border-r '>
                      <h2 className='text-[#004985] opacity-[0.5] text-[56px] font-semibold '>80%</h2>
                      <p className='leading-[17px] text-[16px] font-normal'>{t('salonClient')}</p>
                    </div>
                    <div className='max-w-[190px]'>
                      <h2 className='text-[#004985] opacity-[0.5] text-[56px] font-semibold '>90%</h2>
                      <p className='leading-[17px] text-[16px] font-normal'>{t('onlineBooking')}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          <div className=''>

            {/* Hero Section */}
            <div className='xl:flex lg:flex tablet:flex justify-end items-end hidden'>
              <div className='absolute xl:-top-20 lg:-top-20 top-0 bottom-0 -right-[12.2%] 3xl:border 3xl:-right-[10%]'>
                <img src={Hero} className='object-cover xl:w-[780px] lg:w-[780px] xl:h-[780px] lg:h-[780px] w-[500px] ' />
              </div>
            </div>
            <div className='h-[96px]  absolute right-2  items-center pr-[120px] xl:flex lg:flex hidden'>
              <SocialIcons />
            </div>
            <div className="tablet:block hidden absolute right-[6%] top-[15%]">
                  <img src={lng === "en" ? phoneNew : phonePl} className='object-cover' width={350} height={350} />
                </div>
            <div className='absolute right-[6%] top-[20%] xl:block lg:block hidden phone_mock'>
              <img src={lng === "en" ? phoneNew : phonePl} className='object-cover' width={540} height={500} />
            </div>
          </div>
        </div>
      </div >
    </>

  )
}

export default Banner