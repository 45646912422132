"use client"

import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../partials/Transition';
import { IoMdArrowDropdown } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux';
import { changeLang } from '../../Redux/UserAuthSlice/UserAuthSlice';
import i18next from 'i18next';
import us from '../../assets/us.png'
import pl from '../../assets/pl.png'

function LanguageSelector() {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selected, setSelected] = useState(0);

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // const authState = userSelector((state) => state.auth?.language);
    const lng = useSelector((state) => state?.resize?.language)
    

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });


    const dispatch = useDispatch()



    const handleDrops = (type) => {
        dispatch(changeLang(type))
        i18next.changeLanguage(type)
        setDropdownOpen(false)

    };

    useEffect(() => {
        i18next.changeLanguage(lng)
    }, [lng])





    return (
        <div className="relative">
            <button
                ref={trigger}
                className={` px-2 py-1.5 w-full  flex  flex-col justify-between rounded-md  bg-white border-slate-200 hover:border-slate-300 text-slate-500 hover:text-slate-600`}

                aria-label="Select date range"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >

                <div className='flex'>
                    <span className="flex items-center text-[#707070]">
                        <span className='text-[16px]'>
                            {lng === "pl" ?
                                <img src={pl} alt="" width={20} height={20} />
                                :
                                <img src={us} alt="" width={20} height={20} />
                            }
                        </span>
                    </span>
                    <IoMdArrowDropdown className={`text-[25px] `} />
                </div>
            </button>
            <Transition
                show={dropdownOpen}
                tag="div"
                className="z-10 absolute top-full right-0 w-full p-1  bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
                enter="transition ease-out duration-100 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-100"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                    ref={dropdown}
                    className="font-medium text-sm text-slate-600 "
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    {lng === "pl" ?
                        <button
                            tabIndex="0"
                            className={`flex items-center justify-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer`}
                            onClick={() => handleDrops("en")}
                        >
                            <img src={us} alt="" width={20} height={20} />
                        </button>
                        :
                        <button
                            tabIndex="0"
                            className={`flex items-center justify-center w-full hover:bg-slate-50 py-1 px-3 cursor-pointer`}
                            onClick={() => handleDrops("pl")}
                        >
                            <img src={pl} alt="" width={20} height={20} />
                        </button>
                    }
                </div>
            </Transition>
        </div>
    );
}

export default LanguageSelector;


