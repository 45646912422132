import Choose1 from '../assets/MockUps/ch1.svg'
import Choose2 from '../assets/MockUps/ch2.svg'
import Choose3 from '../assets/MockUps/ch3.svg'
import Choose4 from '../assets/MockUps/ch4.svg'
import Choose5 from '../assets/MockUps/ch5.svg'
import Choose6 from '../assets/MockUps/ch6.svg'
import Choose7 from '../assets/MockUps/ch7.svg'
import Choose8 from '../assets/MockUps/ch8.svg'
import Choose9 from '../assets/MockUps/ch9.svg'

import offer1 from '../assets/Aesthetic Medicine.jpg'
import offer2 from '../assets/Eyelashes and eyebrows.jpg'
import offer3 from '../assets/Facial.jpg'
import offer4 from '../assets/Full body treatments.jpg'
import offer5  from '../assets/MockUps/mak.jpeg'
import offer6 from '../assets/Massage and spa.jpg'
import offer7 from '../assets/Hairdresser.jpg'
import offer8 from '../assets/Permanent makeup.jpg'
import offer9 from '../assets/Tattoo.jpg'
import offer10 from '../assets/Waxing_ depilation.jpg'
import offer11 from '../assets/Others.jpg'
import offer12 from '../assets/Barber.jpg'
import offer13 from '../assets/MockUps/w_hairs.jpeg'
import offer14 from '../assets/Nails.jpg'


export const OfferData = [
    { id: 6 , img: offer7, heading: "hairdress",imghover: offer7  },
    { id: 12 , img: offer12, heading: "manhair",imghover: offer7 },
    { id: 13 , img: offer13, heading: "womanhair", imghover: offer7},
    { id: 14 , img: offer14, heading: "nails",imghover: offer7 },
    { id: 0 , img: offer1, heading: "aesthetic", imghover: offer7},
    { id: 1 , img: offer2, heading: "eylish",imghover: offer7 },
    { id: 2 , img: offer3, heading: "facial", imghover: offer7},
    { id: 3 , img: offer4, heading: "fullbody", imghover: offer7},
    { id: 4 , img: offer5, heading: "makeup", imghover: offer7},
    { id: 5 , img: offer6, heading: "messagespa",imghover: offer7 },
    { id: 7 , img: offer8, heading: "permanent", imghover: offer7},
    { id: 8 , img: offer9, heading: "tattos",imghover: offer7 },
    { id: 9 , img: offer10, heading: "depilation",imghover: offer7 },
    { id: 10 , img: offer11, heading: "others",imghover: offer7 },
];

export const ChooseData = [
    { img: Choose1, heading: "userFeaturHeading1", subHeading: "userFeaturPara1" },
    { img: Choose2, heading: "userFeaturHeading2", subHeading: "userFeaturPara2" },
    { img: Choose3, heading: "userFeaturHeading3", subHeading: "userFeaturPara3" },
    { img: Choose4, heading: "userFeaturHeading4", subHeading: "userFeaturPara4" },
    { img: Choose5, heading: "userFeaturHeading5", subHeading: "userFeaturPara5" },
    { img: Choose6, heading: "userFeaturHeading6", subHeading: "userFeaturPara6" },
    { img: Choose7, heading: "userFeaturHeading7", subHeading: "userFeaturPara7" },
    { img: Choose8, heading: "userFeaturHeading8", subHeading: "userFeaturPara8" },
    { img: Choose9, heading: "userFeaturHeading9", subHeading: "userFeaturPara9" },
];

export const ChooseDataSellon = [
    { img: Choose1, heading: "SelloneHeading1", subHeading: "SellonePara1" },
    { img: Choose2, heading: "SelloneHeading2", subHeading: "SellonePara2" },
    { img: Choose3, heading: "SelloneHeading3", subHeading: "SellonePara3" },
    { img: Choose4, heading: "SelloneHeading4", subHeading: "SellonePara4" },
    { img: Choose5, heading: "SelloneHeading5", subHeading: "SellonePara5" },
    { img: Choose6, heading: "SelloneHeading6", subHeading: "SellonePara6" },
    { img: Choose7, heading: "SelloneHeading7", subHeading: "SellonePara7" },
    // { img: Choose8, heading: "SelloneHeading8", subHeading: "SellonePara8" },
    // { img: Choose9, heading: "SelloneHeading9", subHeading: "SellonePara9" },
];


