import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    language: "pl",
    

}
export const UserAuthSlice = createSlice({
    name: 'UserAuthSlice',
    initialState,
    reducers: {
        changeLang: (state, action) => {
            state.language = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const { changeLang} = UserAuthSlice.actions

export default UserAuthSlice.reducer