import React, { useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicyPl
  = () => {

    const  language  = useSelector((state) => state?.resize?.language)
    const navigate = useNavigate()

    useEffect(() => {

      const updateCookiePolicyLink = () => {
        const cookiePolicyLink = document.querySelector('a[href*="/privacy-policy"]');
        if (cookiePolicyLink) {
          cookiePolicyLink.href = '/legal/cookie-policy/pl';
          cookiePolicyLink.innerText = 'Cookie Policy';
        }
      };
      const script = document.createElement('script');
      script.src = 'https://cdn.iubenda.com/iubenda.js';
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        updateCookiePolicyLink()
        setTimeout(updateCookiePolicyLink, 1000);
      };

      if (language === "en") {
        navigate('/legal/privacy-policy/en')
      } else {
        navigate('/legal/privacy-policy/pl')
      }

      return () => {
        document.body.removeChild(script);
      };



    }, [language]);



    return (
      <Layout>

        <div
          className='xl:px-[100px] lg:px-[1200px] privacy_pl'
          dangerouslySetInnerHTML={{
            __html: `<a href="https://www.iubenda.com/zasady-i-warunki/88332244" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Zasady i warunki">Zasady i warunki</a>`,
          }}
        />
      </Layout>
    )
  }

export default PrivacyPolicyPl
